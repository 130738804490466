<script setup lang="ts">
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl, { ModalWidth } from '/~/components/mdl/mdl-popup.vue'
import { useConfirmComponent } from '/~/modals/confirm/confirm'

const props = withDefaults(
  defineProps<{
    title: string
    description: string
    icon: {
      path: string
      color: string
    }
    showConfirm?: boolean
    showCancel?: boolean
    confirmLabel?: string
    cancelLabel?: string
    width?: ModalWidth
    onConfirm?: () => Promise<void>
    onCancel?: () => Promise<void>
  }>(),
  {
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    showConfirm: true,
    showCancel: true,
    width: 'sme',
  }
)

const { confirming, cancelling, loading, onConfirm, onCancel } =
  useConfirmComponent({
    onConfirm: props.onConfirm,
    onCancel: props.onCancel,
    hide,
  })

function hide() {
  modal.hide()
}
</script>

<template>
  <base-mdl
    :width="width"
    border-radius="xl"
    :padding="false"
    :closable="!loading"
  >
    <template #header>
      <div class="relative space-y-5 px-8 py-6 text-center">
        <span :class="icon.color">
          <base-icon :svg="icon.path" :size="64" class="mx-auto" />
        </span>
        <span class="absolute right-5 top-1">
          <base-button
            icon="heroicons/outline/x-mark"
            :size="32"
            :disabled="loading"
            @click="hide"
          />
        </span>
        <h2 class="text-center text-2xl leading-7 text-eonx-neutral-800">
          {{ title }}
        </h2>
      </div>
    </template>
    <div class="space-y-5 px-8 py-6">
      <div v-html="description" />
    </div>
    <div class="px-8 py-6">
      <div
        v-if="showConfirm || showCancel"
        :class="{ 'grid grid-cols-2 gap-4': showConfirm && showCancel }"
      >
        <base-button
          v-if="showCancel"
          v-analytics:click="{
            cta: 'Cancel',
            pageGroup: 'Confirm Modal',
            page: props.title,
          }"
          :look="showConfirm ? 'outlined-color' : 'filled'"
          :disabled="loading"
          :loading="cancelling"
          @click="onCancel"
        >
          {{ cancelLabel }}
        </base-button>
        <base-button
          v-if="showConfirm"
          v-analytics:click="{
            cta: 'Confirm',
            pageGroup: 'Confirm Modal',
            page: props.title,
          }"
          :disabled="loading"
          :loading="confirming"
          full-width
          @click="onConfirm"
        >
          {{ confirmLabel }}
        </base-button>
      </div>
    </div>
  </base-mdl>
</template>
